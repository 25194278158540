import { IHttpService } from './IHttpService';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export class EndpointConfig {
    private static _staticBaseUrl = environment.production ? '' :
     //'https://garagedesks.com'
      //'https://dev.garagedesks.com' 
      'https://ksa.garagedesks.com'
    //'http://localhost:2345';
    private _baseUrl = environment.production ? '' : 
    //'https://garagedesks.com' 
     //'https://dev.garagedesks.com' 
      'https://ksa.garagedesks.com'
     //'http://localhost:2345';
    constructor(public endpoint) { }

    public static getMvcUrl(controller, action, id?) {
        return `${this._staticBaseUrl}/api/${controller}/${action}${id == undefined ? '' : `/${id}`}`;
    }

    getEndPoint() {
        return `${this._baseUrl}/api/${this.endpoint}`;
    }

    getEndpointMethod(method) {
        return `${this._baseUrl}/api/${this.endpoint}/${method}`;
    }
}
